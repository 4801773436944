import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  elements: [],
  element: null,

};


const slice = createSlice({
  name: 'element',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
  


    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET elementS
    getelementsSuccess(state, action) {
      state.isLoading = false;
      state.elements = action.payload;
    },


    getProductSuccess(state, action) {
      state.isLoading = false;
      state.element = action.payload;
    },

  },
});
export default slice.reducer;


export function getelements() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('admin/get/all/users');
      console.log(response.data,'company')
      dispatch(slice.actions.getelementsSuccess(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getProduct(id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`admin/get/single/report/${id}`);
      console.log(response.data.element , 'response.data.element');
      dispatch(slice.actions.getProductSuccess(response.data.element));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

