// slices/checkedReportsSlice.js

import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  checkedReport: [],
};

const checkedReportsSlice = createSlice({
  name: 'checkedReports',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCheckedReportsSuccess(state, action) {
      state.isLoading = false;
      state.checkedReport = action.payload;
    },
  },
});

export default checkedReportsSlice.reducer;

export const { startLoading, hasError, getCheckedReportsSuccess } = checkedReportsSlice.actions;

export const getCheckedReports = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get('admin/checked/reports');
    dispatch(getCheckedReportsSuccess(response?.data?.data));
  } catch (error) {
    dispatch(hasError(error));
  }
};
