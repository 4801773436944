import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../../routes/paths';


const navConfig = [
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/home', icon:<Icon icon="ri:dashboard-line" /> },
      { title: 'Hospital', path: PATH_DASHBOARD.dorm.dorm, icon: <Icon icon="material-symbols-light:subscriptions-rounded" /> },
      { title: 'Radiologist', path: PATH_DASHBOARD.element.element, icon: <Icon icon="octicon:organization-16" /> },
      { title: 'Reports', path: PATH_DASHBOARD.dorm.report, icon: <Icon icon="octicon:organization-16" /> },
   
      // { title: 'Card', path: PATH_DASHBOARD.card.card, icon:<Icon icon="fluent:contact-card-16-regular" /> },
      // { title: 'Character', path: PATH_DASHBOARD.character.character, icon: <Icon icon="game-icons:ninja-heroic-stance" /> },
    ],
  },
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/home', icon:<Icon icon="ri:dashboard-line" /> },
      { title: 'Reports', path: PATH_DASHBOARD.magictype.magictype, icon:<Icon icon="material-symbols:library-add-check-outline"/>},
      // { title: 'Radiologists', path: PATH_DASHBOARD.magictype.radiologists, icon:<Icon icon="material-symbols:library-add-check-outline"/>},
    ],
  },
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/home', icon:<Icon icon="ri:dashboard-line" /> },
      { title: 'Reports', path: PATH_DASHBOARD.radiologist.radiologist, icon: <Icon icon="material-symbols:library-add-check-outline"/> },
    ],
  },
];

export default navConfig;
