import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import productReducer from './slices/product';
import elementReducer from './slices/element';
import magictypeReducer from './slices/magictype';
import tagReducer from './slices/tag';
import cardReducer from './slices/card';
import rarityReducer from './slices/rarity';
import spellReducer from './slices/spell';
import characterReducer from './slices/character';
import appointReducer from './slices/appoint';
import slotReducer from './slices/slot';
import DressCode from './slices/DressCode';
import RegistrationForm from './slices/RegistrationForm';
import CheckInfo from './slices/CheckInfo';
import checkedReportsSlice from './slices/checkedReportsSlice';
import hospitalAllCheckedReportsSlice from './slices/hospitalAllCheckedReportsSlice';
import RadiologistAllReportsSlice from './slices/RadiologistAllReportsSlice';
import radiologistAllCheckedReportsSlice from './slices/radiologistAllCheckedReportsSlice';
import ReportPending from './slices/ReportPending';
import ReportApproved from './slices/ReportApproved';

const rootPersistConfig = {
  key: 'root',
  storage,
  // keyPrefix: 'redux-',
  // whitelist: [],
};


const rootReducer = combineReducers({
  element: elementReducer,
  magictype: magictypeReducer,
  tag: tagReducer,
  card: cardReducer,
  rarity: rarityReducer,
  spell: spellReducer,
  character: characterReducer,
  product: productReducer,
  appoint: appointReducer,
  slot: slotReducer,
  dresscode: DressCode,
  registrationForm: RegistrationForm,
  checkInfo: CheckInfo,
  report: ReportPending,
  reportApproved: ReportApproved,
  checkedReports: checkedReportsSlice,
  hospitalAllCheckedReports: hospitalAllCheckedReportsSlice,
  radiologistReports: RadiologistAllReportsSlice,
  radiologistAllCheckedReports: radiologistAllCheckedReportsSlice,
});

export { rootPersistConfig, rootReducer };
