import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  magictypes: [],
};


const slice = createSlice({
  name: 'magictype',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.magictypes = []
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET magictype
    getmagictypeSuccess(state, action) {
      state.isLoading = false;
      state.magictypes = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export const getAllReports = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get('hospital/reports');
    dispatch(slice.actions.getmagictypeSuccess(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error?.message));
  }
};

