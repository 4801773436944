import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  reportsApproved: [],
};

const slice = createSlice({
  name: 'reportsApproved',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getReportsSuccess(state, action) {
      state.isLoading = false;
      state.reportsApproved = action.payload;
    },
  },
});
export default slice.reducer;

export function getApprovedAdminReports() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('admin/get/approved/reports');
      dispatch(slice.actions.getReportsSuccess(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
