/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack, Alert, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../utils/axios';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

export default function LoginForm({ type }) {
  const { login } = useAuth();
  const navigate = useNavigate()
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',

  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,

  });

  const {
    reset,
    setError,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password, type);
      navigate('/dashboard/home')

    } catch (error) {
      console.log(error, "errre");
  
      const errorsObject = error?.errors;
      console.log(errorsObject, "errre");
  
      if (errorsObject && typeof errorsObject === 'object') {
          Object.values(errorsObject).forEach((errorMessages) => {
              if (Array.isArray(errorMessages)) {
                  errorMessages.forEach((msg) => {
                      enqueueSnackbar(msg, {
                          variant: 'error',
                      });
                  });
              }
          });
      } else {
          enqueueSnackbar("An unknown error occurred", {
              variant: 'error',
          });
      }
  }
  
  };

  const handlePasswordKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmit(methods.getValues()); // Call the onSubmit functions
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {/* {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>} */}

        <RHFTextField name="email" label="Email address" />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setValue('password', e.target.value)}
          onKeyPress={handlePasswordKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {type !== 'admin' &&
          <>
            <Link component={RouterLink} variant="subtitle2" to={type === 'hospital' ? '/hospital-registeration' : '/radiologist-registeration'}>
              Don't have an account? Register Now
            </Link>
          </>
        }
      </Stack>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
